import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import "./rentalOrderPriceTrackingFormSubtitle.scss";

interface IRentalOrderPriceTrackingFormSubtitleProps extends React.ComponentPropsWithoutRef<"h3"> { }

export const RentalOrderPriceTrackingFormSubtitle = (props: IRentalOrderPriceTrackingFormSubtitleProps) => {
    return (
        <h3 {...props} className={clsx("rental-order-price-tracking-form-subtitle", props.className)}>
            <FontAwesomeIcon icon={faArrowDown} />
            {props.children}

            <div className="rental-order-price-tracking-form-subtitle__line" />
        </h3>
    );
};
