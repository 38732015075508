import React from "react";
import "./rentalOrderPriceTrackingFormRoot.scss";

interface IRentalOrderPriceTrackingFormRootProps {
    children: React.ReactNode;
}

export const RentalOrderPriceTrackingFormRoot = (props: IRentalOrderPriceTrackingFormRootProps) => {
    return <div className="rental-order-price-tracking-form-root">{props.children}</div>;
};
