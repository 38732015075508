import { Machine } from "@farmact/model/src/model/Machine";
import { useOrganizationContext } from "../../../organization/context/OrganizationContext";
import "./rentalOrderPriceTrackingFormTitle.scss";

interface IRentalOrderPriceTrackingFormTitleProps {
    machineId: Machine["id"];
}

export const RentalOrderPriceTrackingFormTitle = (props: IRentalOrderPriceTrackingFormTitleProps) => {
    const { machines } = useOrganizationContext();

    const machine = machines.find(machine => {
        return machine.id === props.machineId;
    });

    return <h2 className="rental-order-price-tracking-form-title">{machine?.name ?? "Unbekannte Maschine"}</h2>;
};
