import React, { useContext } from "react";
import { Machine } from "@farmact/model/src/model/Machine";
import { Service } from "@farmact/model/src/model/services/Service";
import { Resource } from "@farmact/model/src/model/Resource";
import { GasStation } from "@farmact/model/src/model/GasStation";
import { Employee } from "@farmact/model/src/model/Employee";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";

export interface IOrganizationContext {
    numberOfCustomers: number;
    machines: Machine[];
    services: Service[];
    resources: Resource[];
    gasStations: GasStation[];
    employees: Employee[];
    loadingOrUnloadingPoints: LoadingOrUnloadingPoint[];
    numberOfCustomersLoading: boolean;
    machinesLoading: boolean;
    servicesLoading: boolean;
    resourcesLoading: boolean;
    gasStationsLoading: boolean;
    employeesLoading: boolean;
    loadingPointsLoading: boolean;
}

const OrganizationContext: React.Context<IOrganizationContext> = React.createContext<IOrganizationContext>({
    numberOfCustomers: 0,
    machines: [],
    services: [],
    resources: [],
    gasStations: [],
    employees: [],
    loadingOrUnloadingPoints: [],
    numberOfCustomersLoading: false,
    machinesLoading: false,
    servicesLoading: false,
    resourcesLoading: false,
    gasStationsLoading: false,
    employeesLoading: false,
    loadingPointsLoading: false,
});

export default OrganizationContext;

export function useOrganizationContext() {
    return useContext(OrganizationContext);
}
