import React from "react";
import "./rentalOrderPriceTrackingFormList.scss";

interface IRentalOrderPriceTrackingFormListProps {
    children: React.ReactNode;
}

export const RentalOrderPriceTrackingFormList = (props: IRentalOrderPriceTrackingFormListProps) => {
    return <div className="rental-order-price-tracking-form-list">{props.children}</div>;
};
